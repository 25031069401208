.admonition {
  position: relative;
  margin: 1rem 0;
  padding: 0 0.75rem;
  border-left: .25rem solid;
  overflow: auto;
  @include border-radius($global-border-radius);

  .admonition-title {
    font-weight: bold;
    margin: 0 -0.75rem;
    padding: 0.25rem 1.8rem;
    border-bottom: 1px solid;
    @include border-radius(0);
  }

  .admonition-content {
    padding: 0.5rem 0;
  }

  i.icon {
    font-size: 0.85rem;
    position: absolute;
    top: 0.6rem;
    left: 0.4rem;
  }

  i.details-icon {
    position: absolute;
    top: 0.6rem;
    right: 0.3rem;
  }

  @each $type, $color, $background-color in $admonition-color-map {
    @if $type == 'note' {
      background-color: $background-color;
      border-left-color: $color;

      .admonition-title {
        border-bottom-color: $background-color;
        background-color: opacify($background-color, 0.15);
      }

      &.open .admonition-title {
        background-color: $background-color;
      }

      i.icon {
        color: $color;
      }
    }
    
    @else {
      &.#{$type} {
        background-color: $background-color;
        border-left-color: $color;

        .admonition-title {
          border-bottom-color: $background-color;
          background-color: opacify($background-color, 0.15);
        }

        &.open .admonition-title {
          background-color: $background-color;
        }

        i.icon {
          color: $color;
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0.75rem;
  }
}
