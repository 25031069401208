// Color map of admonition [$type $color $background-color, ... ]
$admonition-color-map:
  'note' #448aff rgba(68, 138, 255, 0.1),
  'abstract' #00b0ff rgba(0, 176, 255, 0.1),
  'info' #00b8d4 rgba(0, 184, 212, 0.1),
  'tip' #00bfa5 rgba(0, 191, 165, 0.1),
  'success' #00c853 rgba(0, 200, 83, 0.1),
  'question' #64dd17 rgba(100, 221, 23, 0.1),
  'warning' #ff9100 rgba(255, 145, 0, 0.1),
  'failure' #ff5252 rgba(255, 82, 82, 0.1),
  'danger' #ff1744 rgba(255, 23, 68, 0.1),
  'bug' #f50057 rgba(245, 0, 87, 0.1),
  'example' #651fff rgba(101, 31, 255, 0.1),
  'quote' #9e9e9e rgba(159, 159, 159, 0.1)
  !default;
