svg.icon {
  display: inline-block;
  width: 1.25em;
  height: 1em;
  text-align: center;

  path {
    fill: currentColor;
  }
}

img.emoji {
  display: inline-block;
  height: 1em;
  width: 1em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
}

.fixit-icon {
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  line-height: 1.5em;
  position: relative;
  vertical-align: text-bottom;
}
